import React from 'react'
import Layout from 'layouts/fr'
import Template from 'templates/jobs'

export default () => {
  return (
    <Layout p={5}>
      <Template lang="fr" />
    </Layout>
  )
}
